import { css } from '@emotion/css';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, { ReactFlowInstance, ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import { Field, ProcessedData, TelemetryDictionary, generateMneString } from './utils';

import EPSPanel from '../module/EPSPanel';
import EpsTextBox from './EpsTextBox';
import BattCell from './BattCell';
import SolarCell from './SolarCell';
import PlaceHolder from './PlaceHolder';
import CustomEdge from './CustomEdge';
import BcrMain from '../module/BcrMain';
import BcrSub from '../module/BcrSub';
import Actuator from './Actuator';
import EpsHTb from './EpsHTb';
import LivePlot from './LivePlot';
import Heater from './Heater';
import { useTimeRangeContext } from './TimeRangeContext';
import SolarArray from './SolarArray';
import VModeBox from './VModeBox';
import { useTelemetryData } from 'hooks/DataProcessing';
import RW from './RW';

type AppProps = {
  dbData: Field[];
  width: number;
  height: number;
  source: string;
  influxData: ProcessedData;
  limitData: Field[];
  // ... other props if there are any
};

const reactAppWrapper = css`

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  font-family: monospace;
}

.react-flow__node {
  z-index: 10 !important;
}

.react-flow__edges {
  z-index: 9 !important;
}

.react-flow__node-epsPanel{
  z-index: 5 !important;
}

.react-flow__node-bcrMain{
  z-index: 6 !important;
}

.react-flow__edge-span{
  z-index: 3 !important;
}

  position: relative;
  background-color: var(--app-background);
  color: #FFFFFF;
  font-size: 35px;
  font-family: monospace;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
`;

const nodeTypes = { epsPanel: EPSPanel,rw:RW,vmodeBox: VModeBox, liveplot: LivePlot, solar: SolarArray, heater: Heater, epsHTb: EpsHTb, actuator: Actuator, bcrMain: BcrMain, bcrSub: BcrSub, battCell: BattCell, solarCell: SolarCell, epstextbox: EpsTextBox, placeHolder: PlaceHolder }
const edgeTypes = { customEdge: CustomEdge }

const App: React.FC<AppProps> = ({ dbData, source, width, height, influxData, limitData }) => {

  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const { guiValues } = useTimeRangeContext();
  // Use refs to always have the current values
  const pbTimeRef = useRef("0");
  const pbLoopRef = useRef(0);
  const { createTelemetryDictionary } = useTelemetryData(dbData, limitData);

  const onInit = useCallback((instance: ReactFlowInstance) => {
    setReactFlowInstance(instance);
  }, []);

  useEffect(() => {
    if (reactFlowInstance ) {
      //@ts-ignore
      reactFlowInstance.fitView();
    }
  }
  ,[width, height, reactFlowInstance]);

  const telemetryDictionary = createTelemetryDictionary();

  const [nodes, setNodes] = useState([
    { id: 'Panel', type: 'epsPanel', position: { x: 550, y: 50 }, data: { 'source': source } },
    { id: 'placeHolder1', type: 'placeHolder', parentNode: 'Panel', position: { x: 1425, y: 1275 }, data: { text: 'To All Loads' } },
    { id: 'placeHolder2', type: 'placeHolder', parentNode: 'Panel', position: { x: 25, y: 1315 }, data: { text: 'Power Gen' } },
    { id: 'placeHolder3', type: 'placeHolder', parentNode: 'Panel', position: { x: 25, y: 1275 }, data: { text: 'Power Loads' } },

    { id: 'Liveplot', type: 'liveplot', parentNode: 'Panel', position: { x: 1300, y: 1975 }, data: { mne: 'pwbv', sc: source } },
    { id: 'Liveplot2', type: 'liveplot', parentNode: 'Panel', position: { x: 2300, y: 1975 }, data: { mne: 'pwbi', sc: source } },

    { id: 'HDRM2', type: 'actuator', parentNode: '', position: { x: 3700, y: 1000 }, data: { name: 'HDRM2', value: { 'En': { 'dbData': telemetryDictionary['En2'], 'fname': '' }, 'V': { 'dbData': telemetryDictionary['Hdrm2 Volt'], 'fname': '' } } } },
    { id: 'HDRM1', type: 'actuator', parentNode: '', position: { x: 400, y: 1000 }, data: { name: 'HDRM1', value: { 'En': { 'dbData': telemetryDictionary['En1'], 'fname': '' }, 'V': { 'dbData': telemetryDictionary['Hdrm1 Volt'], 'fname': '' } } } },

    { id: 'Battery', type: 'epstextbox', parentNode: 'Panel', position: { x: 700, y: 2400 }, data: { name: 'Battery', value: { "Curr": { 'dbData': telemetryDictionary['Battery Curr'], 'fname': '' }, "Volt": { 'dbData': telemetryDictionary['Battery Volt'], 'fname': '' }, "Temp": { 'dbData': telemetryDictionary['Battery Temp'], 'fname': '' }, } } },
    { id: 'BRB', type: 'epstextbox', parentNode: 'Panel', position: { x: 700, y: 2000 }, data: { name: 'Battery Relay Box (BRB)', className: 'BrbBox', oriHeight: '150px', exWidth: '475px', value: { 'K1k2 Coil State': { 'dbData': telemetryDictionary['Brb K1k2 Coil State'], 'fname': '' }, 'Rly1 Sep State': { 'dbData': telemetryDictionary['Brb Sep Rly1 State'], 'fname': '' }, 'Rly2 Sep State': { 'dbData': telemetryDictionary['Brb Sep Rly2 State'], 'fname': '' }, "Temp": { 'dbData': telemetryDictionary['Brb Temp'], 'fname': '' } } } },
    { id: 'SolarArray', type: 'solar', parentNode: 'Panel', position: { x: 50, y: 1950 }, data: { name: 'Solar Array', value: { "SA Curr": { 'dbData': telemetryDictionary['SA Current'], 'fname': '' }, "SA1 Pwr": { 'dbData': telemetryDictionary['SA1 Pwr'], 'fname': '' }, "SA2 Pwr": { 'dbData': telemetryDictionary['SA2 Pwr'], 'fname': '' }, "SA3 Pwr": { 'dbData': telemetryDictionary['SA3 Pwr'], 'fname': '' }, "SA4 Pwr": { 'dbData': telemetryDictionary['SA4 Pwr'], 'fname': '' }, "SA5 Pwr": { 'dbData': telemetryDictionary['SA5 Pwr'], 'fname': '' }, "SA6 Pwr": { 'dbData': telemetryDictionary['SA6 Pwr'], 'fname': '' }, "SA7 Pwr": { 'dbData': telemetryDictionary['SA7 Pwr'], 'fname': '' }, "SA8 Pwr": { 'dbData': telemetryDictionary['SA8 Pwr'], 'fname': '' }, "SA9 Pwr": { 'dbData': telemetryDictionary['SA9 Pwr'], 'fname': '' }, "SA10 Pwr": { 'dbData': telemetryDictionary['SA10 Pwr'], 'fname': '' }, "SA11 Pwr": { 'dbData': telemetryDictionary['SA11 Pwr'], 'fname': '' }, "SA12 Pwr": { 'dbData': telemetryDictionary['SA12 Pwr'], 'fname': '' } } } },

    { id: 'IAU', type: 'bcrMain', parentNode: 'Panel', position: { x: 400, y: 1400 }, data: {} },
    { id: 'SACI', type: 'epsHTb', parentNode: 'IAU', position: { x: 750, y: 300 }, data: { name: 'SACI', className: 'BcrHub', value: { "Current": { 'dbData': telemetryDictionary['Battery Curr SACI'], 'fname': '' }, "Voltage": { 'dbData': telemetryDictionary['Battery Volt SACI'], 'fname': '' } } } },
    { id: 'PAPI6-3', type: 'epstextbox', parentNode: 'IAU', position: { x: 1850, y: 50 }, data: { name: 'PAPI6-3', value: { 'PAPI3 Current': { 'dbData': telemetryDictionary['PAPI3 Current'], 'fname': '' } } } },
    { id: 'PAPI6-2', type: 'epstextbox', parentNode: 'IAU', position: { x: 1250, y: 50 }, data: { name: 'PAPI6-2', value: { 'PAPI2 Current': { 'dbData': telemetryDictionary['PAPI2 Current'], 'fname': '' } } } },
    { id: 'PAPI6-1', type: 'epstextbox', parentNode: 'IAU', position: { x: 650, y: 50 }, data: { name: 'PAPI6-1', value: { 'PAPI1 Current': { 'dbData': telemetryDictionary['PAPI1 Current'], 'fname': '' } } } },
    { id: 'MCLASI', type: 'epstextbox', parentNode: 'IAU', position: { x: 50, y: 50 }, data: { name: 'MCLASI', value: { '5v': { 'dbData': telemetryDictionary['MCLASI 5V'], 'fname': '' }, '2.5V': { 'dbData': telemetryDictionary['MCLASI 2.5V'], 'fname': '' }, '3.3V': { 'dbData': telemetryDictionary['MCLASI Bus 3.3V'], 'fname': '' } } } },

    { id: 'ST', type: 'epstextbox', parentNode: 'Panel', position: { x: 2750, y: 400 }, data: { name: 'Star Tracker', className: 'SBox', value: { 'Pwr': { 'dbData': telemetryDictionary['St Pwr'], 'fname': '' }, "Aux Volt": { 'dbData': telemetryDictionary['St Aux Volt'], 'fname': '' }, "BRAM Volt": { 'dbData': telemetryDictionary['St BRAM Volt'], 'fname': '' }, "INT Volt": { 'dbData': telemetryDictionary['St INT Volt'], 'fname': '' }, "ODDR Volt": { 'dbData': telemetryDictionary['St ODDR Volt'], 'fname': '' }, "PAUX Volt": { 'dbData': telemetryDictionary['St PAUX Volt'], 'fname': '' }, "PINT Volt": { 'dbData': telemetryDictionary['St PINT Volt'], 'fname': '' }, "REF FN Volt": { 'dbData': telemetryDictionary['St REF FN Volt'], 'fname': '' }, "REF FP Volt": { 'dbData': telemetryDictionary['St REF FP Volt'], 'fname': '' }, "OVP/VNDDR Volt": { 'dbData': telemetryDictionary['St VP/VN Volt'], 'fname': '' } } } },
    { id: 'RW1', type: 'rw', parentNode: 'Panel', position: { x: 1950, y: 250 }, data: { name: 'RW 1', pwr:telemetryDictionary['RW1 Pwr'],value: { 'motori': { 'dbData': telemetryDictionary['RW1 MOTORi'], 'fname': '' },'motorv': { 'dbData': telemetryDictionary['RW1 MOTORv'], 'fname': '' },'busi': { 'dbData': telemetryDictionary['RW1 BUSi'], 'fname': '' },'busv': { 'dbData': telemetryDictionary['RW1 BUSv'], 'fname': '' }}}},
    { id: 'RW2', type: 'rw', parentNode: 'Panel', position: { x: 2350, y: 250 }, data: { name: 'RW 2', pwr:telemetryDictionary['RW2 Pwr'],value: { 'motori': { 'dbData': telemetryDictionary['RW2 MOTORi'], 'fname': '' },'motorv': { 'dbData': telemetryDictionary['RW2 MOTORv'], 'fname': '' },'busi': { 'dbData': telemetryDictionary['RW2 BUSi'], 'fname': '' },'busv': { 'dbData': telemetryDictionary['RW2 BUSv'], 'fname': '' }}}},
    { id: 'RW3', type: 'rw', parentNode: 'Panel', position: { x: 1950, y: 750 }, data: { name: 'RW 3', pwr:telemetryDictionary['RW3 Pwr'],value: { 'motori': { 'dbData': telemetryDictionary['RW3 MOTORi'], 'fname': '' },'motorv': { 'dbData': telemetryDictionary['RW3 MOTORv'], 'fname': '' },'busi': { 'dbData': telemetryDictionary['RW3 BUSi'], 'fname': '' },'busv': { 'dbData': telemetryDictionary['RW3 BUSv'], 'fname': '' }}}},
    { id: 'RW4', type: 'rw', parentNode: 'Panel', position: { x: 2350, y: 750 }, data: { name: 'RW 4', pwr:telemetryDictionary['RW4 Pwr'],value: { 'motori': { 'dbData': telemetryDictionary['RW4 MOTORi'], 'fname': '' },'motorv': { 'dbData': telemetryDictionary['RW4 MOTORv'], 'fname': '' },'busi': { 'dbData': telemetryDictionary['RW4 BUSi'], 'fname': '' },'busv': { 'dbData': telemetryDictionary['RW4 BUSv'], 'fname': '' }}}},
    { id: 'Heater', type: 'epstextbox', parentNode: 'Panel', position: { x: 1250, y: 300 }, data: { name: 'Heater', className: 'HeaterBox', value: { "Battery Pwr": { 'dbData': telemetryDictionary['Battery Heater Pwr'], 'fname': '' }, "Payload Pwr": { 'dbData': telemetryDictionary['Payload Op Heater Pwr'], 'fname': '' }, "NFI Cam Pwr": { 'dbData': telemetryDictionary['NFI Cam Heater Pwr'], 'fname': '' }, "WFI Cam Pwr": { 'dbData': telemetryDictionary['WFI Cam Heater Pwr'], 'fname': '' }, "N2H4 Pwr": { 'dbData': telemetryDictionary['N2H4 Heater Pwr'], 'fname': '' }, "PF Pwr": { 'dbData': telemetryDictionary['PF Heater Pwr'], 'fname': '' }, "PLV Pwr": { 'dbData': telemetryDictionary['PLV Heater Pwr'], 'fname': '' }, "PT Pwr": { 'dbData': telemetryDictionary['PT Heater Pwr'], 'fname': '' }, "ST Pwr": { 'dbData': telemetryDictionary['ST Heater Pwr'], 'fname': '' }, "GN2 Pwr": { 'dbData': telemetryDictionary['GN2 Heater Pwr'], 'fname': '' } } } },
    { id: 'S-Band', type: 'epstextbox', parentNode: 'Panel', position: { x: 150, y: 550 }, data: { name: 'S-Band', className: 'SBBox', value: { 'Tx Pwr': { 'dbData': telemetryDictionary['Tx Pwr'], 'fname': '' }, 'Tx 18v': { 'dbData': telemetryDictionary['Tx 18v'], 'fname': '' }, 'NB 10V': { 'dbData': telemetryDictionary['NB 10V'], 'fname': '' }, 'PA 5v': { 'dbData': telemetryDictionary['PA 5v'], 'fname': '' } } } },

    { id: 'Payload', type: 'epsHTb', parentNode: 'Panel', position: { x: 200, y: 50 }, data: { name: 'Payload', className: 'PayloadBox', value: { "NFI Cam Pwr": { 'dbData': telemetryDictionary['NFI Cam Pwr'], 'fname': '' }, "NFI Cam Curr": { 'dbData': telemetryDictionary['NFI Cam Current'], 'fname': '' }, "WFI Cam Pwr": { 'dbData': telemetryDictionary['NFI Cam Pwr'], 'fname': '' }, "WFI Cam Curr": { 'dbData': telemetryDictionary['WFI Cam Current'], 'fname': '' }, "GCI Pwr": { 'dbData': telemetryDictionary['GCI Pwr'], 'fname': '' }, "GCI Curr": { 'dbData': telemetryDictionary['GCI Current'], 'fname': '' }, "GISSMO Pwr": { 'dbData': telemetryDictionary['GISSMO Pwr'], 'fname': '' }, "GISSMO Curr": { 'dbData': telemetryDictionary['GISSMO Current'], 'fname': '' }, "FW Pwr": { 'dbData': telemetryDictionary['FW Pwr'], 'fname': '' }, "FW Curr": { 'dbData': telemetryDictionary['FW Current'], 'fname': '' }, "Dled Pwr": { 'dbData': telemetryDictionary['Dled Pwr'], 'fname': '' }, "DOOR Curr": { 'dbData': telemetryDictionary['DOOR Current'], 'fname': '' } } } },

    { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:550, y:-105}, data:{name:'', playBackValue: {time: pbTimeRef.current, loop: pbLoopRef.current}}},

  ]);

  const createNodes = (telemetryDictionary: TelemetryDictionary) => {
    return [
      { id: 'Panel', type: 'epsPanel', position: { x: 550, y: 50 }, data: { 'source': source } },
      { id: 'placeHolder1', type: 'placeHolder', parentNode: 'Panel', position: { x: 1425, y: 1275 }, data: { text: 'To All Loads' } },
      { id: 'placeHolder2', type: 'placeHolder', parentNode: 'Panel', position: { x: 25, y: 1315 }, data: { text: 'Power Gen' } },
      { id: 'placeHolder3', type: 'placeHolder', parentNode: 'Panel', position: { x: 25, y: 1275 }, data: { text: 'Power Loads' } },

      { id: 'Liveplot', type: 'liveplot', parentNode: 'Panel', position: { x: 1300, y: 1975 }, data: { mne: 'pwbv', sc: source } },
      { id: 'Liveplot2', type: 'liveplot', parentNode: 'Panel', position: { x: 2300, y: 1975 }, data: { mne: 'pwbi', sc: source } },

      { id: 'HDRM2', type: 'actuator', parentNode: '', position: { x: 3700, y: 1000 }, data: { name: 'HDRM2', value: { 'En': { 'dbData': telemetryDictionary['En2'], 'fname': '' }, 'V': { 'dbData': telemetryDictionary['Hdrm2 Volt'], 'fname': '' } } } },
      { id: 'HDRM1', type: 'actuator', parentNode: '', position: { x: 400, y: 1000 }, data: { name: 'HDRM1', value: { 'En': { 'dbData': telemetryDictionary['En1'], 'fname': '' }, 'V': { 'dbData': telemetryDictionary['Hdrm1 Volt'], 'fname': '' } } } },

      { id: 'Battery', type: 'epstextbox', parentNode: 'Panel', position: { x: 700, y: 2400 }, data: { name: 'Battery', value: { "Curr": { 'dbData': telemetryDictionary['Battery Curr'], 'fname': '' }, "Volt": { 'dbData': telemetryDictionary['Battery Volt'], 'fname': '' }, "Temp": { 'dbData': telemetryDictionary['Battery Temp'], 'fname': '' }, } } },
      { id: 'BRB', type: 'epstextbox', parentNode: 'Panel', position: { x: 700, y: 2000 }, data: { name: 'Battery Relay Box (BRB)', className: 'BrbBox', oriHeight: '150px', exWidth: '475px', value: { 'K1k2 Coil State': { 'dbData': telemetryDictionary['Brb K1k2 Coil State'], 'fname': '' }, 'Rly1 Sep State': { 'dbData': telemetryDictionary['Brb Sep Rly1 State'], 'fname': '' }, 'Rly2 Sep State': { 'dbData': telemetryDictionary['Brb Sep Rly2 State'], 'fname': '' }, "Temp": { 'dbData': telemetryDictionary['Brb Temp'], 'fname': '' } } } },
      { id: 'SolarArray', type: 'solar', parentNode: 'Panel', position: { x: 50, y: 1950 }, data: { name: 'Solar Array', value: { "SA Curr": { 'dbData': telemetryDictionary['SA Current'], 'fname': '' }, "SA1 Pwr": { 'dbData': telemetryDictionary['SA1 Pwr'], 'fname': '' }, "SA2 Pwr": { 'dbData': telemetryDictionary['SA2 Pwr'], 'fname': '' }, "SA3 Pwr": { 'dbData': telemetryDictionary['SA3 Pwr'], 'fname': '' }, "SA4 Pwr": { 'dbData': telemetryDictionary['SA4 Pwr'], 'fname': '' }, "SA5 Pwr": { 'dbData': telemetryDictionary['SA5 Pwr'], 'fname': '' }, "SA6 Pwr": { 'dbData': telemetryDictionary['SA6 Pwr'], 'fname': '' }, "SA7 Pwr": { 'dbData': telemetryDictionary['SA7 Pwr'], 'fname': '' }, "SA8 Pwr": { 'dbData': telemetryDictionary['SA8 Pwr'], 'fname': '' }, "SA9 Pwr": { 'dbData': telemetryDictionary['SA9 Pwr'], 'fname': '' }, "SA10 Pwr": { 'dbData': telemetryDictionary['SA10 Pwr'], 'fname': '' }, "SA11 Pwr": { 'dbData': telemetryDictionary['SA11 Pwr'], 'fname': '' }, "SA12 Pwr": { 'dbData': telemetryDictionary['SA12 Pwr'], 'fname': '' } } } },

      { id: 'IAU', type: 'bcrMain', parentNode: 'Panel', position: { x: 400, y: 1400 }, data: {} },
      { id: 'SACI', type: 'epsHTb', parentNode: 'IAU', position: { x: 750, y: 300 }, data: { name: 'SACI', className: 'BcrHub', value: { "Current": { 'dbData': telemetryDictionary['Battery Curr SACI'], 'fname': '' }, "Voltage": { 'dbData': telemetryDictionary['Battery Volt SACI'], 'fname': '' } } } },
      { id: 'PAPI6-3', type: 'epstextbox', parentNode: 'IAU', position: { x: 1850, y: 50 }, data: { name: 'PAPI6-3', value: { 'PAPI3 Current': { 'dbData': telemetryDictionary['PAPI3 Current'], 'fname': '' } } } },
      { id: 'PAPI6-2', type: 'epstextbox', parentNode: 'IAU', position: { x: 1250, y: 50 }, data: { name: 'PAPI6-2', value: { 'PAPI2 Current': { 'dbData': telemetryDictionary['PAPI2 Current'], 'fname': '' } } } },
      { id: 'PAPI6-1', type: 'epstextbox', parentNode: 'IAU', position: { x: 650, y: 50 }, data: { name: 'PAPI6-1', value: { 'PAPI1 Current': { 'dbData': telemetryDictionary['PAPI1 Current'], 'fname': '' } } } },
      { id: 'MCLASI', type: 'epstextbox', parentNode: 'IAU', position: { x: 50, y: 50 }, data: { name: 'MCLASI', value: { '5v': { 'dbData': telemetryDictionary['MCLASI 5V'], 'fname': '' }, '2.5V': { 'dbData': telemetryDictionary['MCLASI 2.5V'], 'fname': '' }, '3.3V': { 'dbData': telemetryDictionary['MCLASI Bus 3.3V'], 'fname': '' } } } },

      { id: 'ST', type: 'epstextbox', parentNode: 'Panel', position: { x: 2750, y: 400 }, data: { name: 'Star Tracker', className: 'SBox', value: { 'Pwr': { 'dbData': telemetryDictionary['St Pwr'], 'fname': '' }, "Aux Volt": { 'dbData': telemetryDictionary['St Aux Volt'], 'fname': '' }, "BRAM Volt": { 'dbData': telemetryDictionary['St BRAM Volt'], 'fname': '' }, "INT Volt": { 'dbData': telemetryDictionary['St INT Volt'], 'fname': '' }, "ODDR Volt": { 'dbData': telemetryDictionary['St ODDR Volt'], 'fname': '' }, "PAUX Volt": { 'dbData': telemetryDictionary['St PAUX Volt'], 'fname': '' }, "PINT Volt": { 'dbData': telemetryDictionary['St PINT Volt'], 'fname': '' }, "REF FN Volt": { 'dbData': telemetryDictionary['St REF FN Volt'], 'fname': '' }, "REF FP Volt": { 'dbData': telemetryDictionary['St REF FP Volt'], 'fname': '' }, "OVP/VNDDR Volt": { 'dbData': telemetryDictionary['St VP/VN Volt'], 'fname': '' } } } },
      { id: 'RW1', type: 'rw', parentNode: 'Panel', position: { x: 1950, y: 250 }, data: { name: 'RW 1', pwr:telemetryDictionary['RW1 Pwr'],value: { 'motori': { 'dbData': telemetryDictionary['RW1 MOTORi'], 'fname': '' },'motorv': { 'dbData': telemetryDictionary['RW1 MOTORv'], 'fname': '' },'busi': { 'dbData': telemetryDictionary['RW1 BUSi'], 'fname': '' },'busv': { 'dbData': telemetryDictionary['RW1 BUSv'], 'fname': '' }}}},
      { id: 'RW2', type: 'rw', parentNode: 'Panel', position: { x: 2350, y: 250 }, data: { name: 'RW 2', pwr:telemetryDictionary['RW2 Pwr'],value: { 'motori': { 'dbData': telemetryDictionary['RW2 MOTORi'], 'fname': '' },'motorv': { 'dbData': telemetryDictionary['RW2 MOTORv'], 'fname': '' },'busi': { 'dbData': telemetryDictionary['RW2 BUSi'], 'fname': '' },'busv': { 'dbData': telemetryDictionary['RW2 BUSv'], 'fname': '' }}}},
      { id: 'RW3', type: 'rw', parentNode: 'Panel', position: { x: 1950, y: 750 }, data: { name: 'RW 3', pwr:telemetryDictionary['RW3 Pwr'],value: { 'motori': { 'dbData': telemetryDictionary['RW3 MOTORi'], 'fname': '' },'motorv': { 'dbData': telemetryDictionary['RW3 MOTORv'], 'fname': '' },'busi': { 'dbData': telemetryDictionary['RW3 BUSi'], 'fname': '' },'busv': { 'dbData': telemetryDictionary['RW3 BUSv'], 'fname': '' }}}},
      { id: 'RW4', type: 'rw', parentNode: 'Panel', position: { x: 2350, y: 750 }, data: { name: 'RW 4', pwr:telemetryDictionary['RW4 Pwr'],value: { 'motori': { 'dbData': telemetryDictionary['RW4 MOTORi'], 'fname': '' },'motorv': { 'dbData': telemetryDictionary['RW4 MOTORv'], 'fname': '' },'busi': { 'dbData': telemetryDictionary['RW4 BUSi'], 'fname': '' },'busv': { 'dbData': telemetryDictionary['RW4 BUSv'], 'fname': '' }}}},
      { id: 'Heater', type: 'epstextbox', parentNode: 'Panel', position: { x: 1250, y: 300 }, data: { name: 'Heater', className: 'HeaterBox', value: { "Battery Pwr": { 'dbData': telemetryDictionary['Battery Heater Pwr'], 'fname': '' }, "Payload Pwr": { 'dbData': telemetryDictionary['Payload Op Heater Pwr'], 'fname': '' }, "NFI Cam Pwr": { 'dbData': telemetryDictionary['NFI Cam Heater Pwr'], 'fname': '' }, "WFI Cam Pwr": { 'dbData': telemetryDictionary['WFI Cam Heater Pwr'], 'fname': '' }, "N2H4 Pwr": { 'dbData': telemetryDictionary['N2H4 Heater Pwr'], 'fname': '' }, "PF Pwr": { 'dbData': telemetryDictionary['PF Heater Pwr'], 'fname': '' }, "PLV Pwr": { 'dbData': telemetryDictionary['PLV Heater Pwr'], 'fname': '' }, "PT Pwr": { 'dbData': telemetryDictionary['PT Heater Pwr'], 'fname': '' }, "ST Pwr": { 'dbData': telemetryDictionary['ST Heater Pwr'], 'fname': '' }, "GN2 Pwr": { 'dbData': telemetryDictionary['GN2 Heater Pwr'], 'fname': '' } } } },
      { id: 'S-Band', type: 'epstextbox', parentNode: 'Panel', position: { x: 150, y: 550 }, data: { name: 'S-Band', className: 'SBBox', value: { 'Tx Pwr': { 'dbData': telemetryDictionary['Tx Pwr'], 'fname': '' }, 'Tx 18v': { 'dbData': telemetryDictionary['Tx 18v'], 'fname': '' }, 'NB 10V': { 'dbData': telemetryDictionary['NB 10V'], 'fname': '' }, 'PA 5v': { 'dbData': telemetryDictionary['PA 5v'], 'fname': '' } } } },

      { id: 'Payload', type: 'epsHTb', parentNode: 'Panel', position: { x: 200, y: 50 }, data: { name: 'Payload', className: 'PayloadBox', value: { "NFI Cam Pwr": { 'dbData': telemetryDictionary['NFI Cam Pwr'], 'fname': '' }, "NFI Cam Curr": { 'dbData': telemetryDictionary['NFI Cam Current'], 'fname': '' }, "WFI Cam Pwr": { 'dbData': telemetryDictionary['NFI Cam Pwr'], 'fname': '' }, "WFI Cam Curr": { 'dbData': telemetryDictionary['WFI Cam Current'], 'fname': '' }, "GCI Pwr": { 'dbData': telemetryDictionary['GCI Pwr'], 'fname': '' }, "GCI Curr": { 'dbData': telemetryDictionary['GCI Current'], 'fname': '' }, "GISSMO Pwr": { 'dbData': telemetryDictionary['GISSMO Pwr'], 'fname': '' }, "GISSMO Curr": { 'dbData': telemetryDictionary['GISSMO Current'], 'fname': '' }, "FW Pwr": { 'dbData': telemetryDictionary['FW Pwr'], 'fname': '' }, "FW Curr": { 'dbData': telemetryDictionary['FW Current'], 'fname': '' }, "Dled Pwr": { 'dbData': telemetryDictionary['Dled Pwr'], 'fname': '' }, "DOOR Curr": { 'dbData': telemetryDictionary['DOOR Current'], 'fname': '' } } } },

      { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:550, y:-105}, data:{name:'', playBackValue: {time: pbTimeRef.current, loop: pbLoopRef.current}}},

    ]
  }

  useEffect(() => {
    if (!guiValues.current.pbisInPlayBack) {
      return;
    }

    let isCancelled = false;

    const processData = async () => {
      // Initialize an empty object to store telemetry data
      let aggregatedData: TelemetryDictionary = {};
      const totalLoops = Object.keys(influxData).length;

      for (let i = 0; i < totalLoops; i++) {
        // Check if the process should stop
        if (!guiValues.current.pbisInPlayBack || isCancelled) {
          break;
        }

        const key = Object.keys(influxData)[i];
        const selectedData = influxData[key];
        pbTimeRef.current = key;
        const newValue = (i + 1) / totalLoops;
        pbLoopRef.current = newValue;


        // Loop through each item in selectedData and update the aggregatedData object
        selectedData.forEach(item => {
          // If the key already exists, update the telemetry value
          if (aggregatedData[item.name]) {
            if (item.cnvValue !== '') {
              aggregatedData[item.name].telemetry = item.cnvValue;
            }
          } else {
            aggregatedData[item.name] = {
              telemetry: item.cnvValue,
              mne: item.mne,
              limit: "",
              unit: "",
              spacecraft: "",
              live: "rgb(68, 169, 241)",
            };
          }
        });

        // Create nodes from the aggregatedData object
        const newNodes = createNodes(aggregatedData);
        setNodes(newNodes);

        // Log the current loop progress
        //console.log(`${i + 1}/${totalLoops}`);

        // Wait for 2 seconds before processing the next item
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      guiValues.current.pbstopPlay()
      //console.log('Playback completed');
    };

    processData();

    // Cleanup function to cancel the loop if pbisInPlayBack changes
    return () => {
      isCancelled = true;
    };
  }, [influxData, guiValues.current.pbisInPlayBack]);

  useEffect(() => {
    //console.log('Playback started asfafa', guiValues.pbisInPlayBack);
    if (guiValues.current.pbisInPlayBack) {
      return;
    }
    const telemetryDictionary1 = createTelemetryDictionary();

    const newNodes = createNodes(telemetryDictionary1); // Function to create nodes from telemetryDictionary
    setNodes(newNodes);
  }, [dbData, createTelemetryDictionary]);

  enum MarkerType {
    Arrow = 'arrow',
    ArrowClosed = 'arrowclosed',
  }

  console.log('dafasStringString', generateMneString())

  const arrow = { type: MarkerType.ArrowClosed, color: 'white', orient: 'auto-start-reverse', width: 15, height: 15 };

  const initialEdges = [
    { id: 'edge1', source: 'HDRM1', sourceHandle: 'source-left', target: 'HDRM2', targetHandle: 'target-right', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, zIndex: 10 },

    { id: 'edge2', source: 'SolarArray', sourceHandle: 'source-right', target: 'BRB', targetHandle: 'target-left', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge3', source: 'SolarArray', sourceHandle: 'source-top', target: 'SACI', targetHandle: 'target-left-1', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge4', source: 'Battery', sourceHandle: 'source-top', target: 'BRB', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge5', source: 'BRB', sourceHandle: 'source-top', target: 'SACI', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge6', source: 'SACI', sourceHandle: 'source-left', target: 'MCLASI', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge7', source: 'SACI', sourceHandle: 'source-top-1', target: 'PAPI6-1', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge8', source: 'SACI', sourceHandle: 'source-top-2', target: 'PAPI6-2', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge9', source: 'SACI', sourceHandle: 'source-right', target: 'PAPI6-3', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge10', source: 'PAPI6-1', sourceHandle: 'source-top-1', target: 'Heater', targetHandle: 'target-bottom-2', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge11', source: 'PAPI6-2', sourceHandle: 'source-top-6', target: 'Heater', targetHandle: 'target-bottom-1', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge12', source: 'SACI', sourceHandle: 'source-left-1', target: 'S-Band', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge13', source: 'PAPI6-1', sourceHandle: 'source-top-2', target: 'S-Band', targetHandle: 'target-bottom-3', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge14', source: 'PAPI6-2', sourceHandle: 'source-top-4', target: 'RW', targetHandle: 'target-bottom-4', type: 'straight', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge15', source: 'PAPI6-2', sourceHandle: 'source-top-5', target: 'ST', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    // {id: 'edge16', source: 'PAPI6-1',sourceHandle: 'source-top', target: 'IMU',targetHandle:'target-bottom', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},
    { id: 'edge17', source: 'PAPI6-1', sourceHandle: 'source-top-3', target: 'Payload', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge18', source: 'PAPI6-2', sourceHandle: 'source-top', target: 'Payload', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge19', source: 'PAPI6-3', sourceHandle: 'source-right', target: 'Payload', targetHandle: 'target-right', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },

  ];

  const proOptions = { hideAttribution: true };

  return (
    <ReactFlowProvider>
      <div className={reactAppWrapper} style={{ height, width }}>
        <ReactFlow
          nodes={nodes}
          onInit={onInit}
          edges={initialEdges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          proOptions={proOptions}
          //defaultViewport={{ x: options.X, y: options.Y, zoom: options.Zoom }}
          minZoom={0.1}
          panOnDrag={true}
          zoomOnScroll={true}
          zoomOnPinch={true}
          zoomOnDoubleClick={true}
          fitView={true}
        />
      </div>
    </ReactFlowProvider>
  );

}

export default App;







