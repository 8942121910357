import { css } from '@emotion/css';
import React from "react";
import { Handle, Position } from 'reactflow';
import { InnerData } from 'utils/type';

interface BattCellProp {
  data: {
    name: string;
    value: InnerData;
    className: string;
  };
}


const BattCell: React.FC<BattCellProp> = ({ data }) => {

  const battbox = css`
  `
  const battSvgBox = css`
    display: flex;
    flex-wrap: wrap;
    width: 115px;
  `

  return (
    <div className={battbox}>

      <Handle type="target" position={Position.Top} id="target-top" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Left} id="target-left" style={{ opacity: 0 }} />
      {/* <Handle type="target" position={Position.Left} id="target-left-1" style={{ opacity: 0, top:40 }} />
      <Handle type="target" position={Position.Left} id="target-left-2" style={{ opacity: 0, top:160 }} />
      <Handle type="target" position={Position.Left} id="target-left-3" style={{ opacity: 0, top:280 }} />
      <Handle type="target" position={Position.Left} id="target-left-4" style={{ opacity: 0, top:390 }} />
      <Handle type="target" position={Position.Left} id="target-left-5" style={{ opacity: 0, top:510 }} /> */}
      <Handle type="target" position={Position.Right} id="target-right" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Top} id="source-top" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Left} id="source-left" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Right} id="source-right" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{ opacity: 0 }} />

        <div className={battSvgBox}>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="115px" height="115px" viewBox="-0.5 -0.5 165 165">
            <defs />
            <g>
              {/* <path d="M 0 41 C 0 23.88 35.82 10 80 10 C 101.22 10 121.57 13.27 136.57 19.08 C 151.57 24.89 160 32.78 160 41 L 160 479 C 160 496.12 124.18 510 80 510 C 35.82 510 0 496.12 0 479 Z"
            fill="rgb(38,38,38)"   stroke="white" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all" />
                  <path d="M 160 41 C 160 58.12 124.18 72 80 72 C 35.82 72 0 58.12 0 41" fill="rgb(38,38,38)" stroke="white" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all" /> */}
              <rect x="5" y="5" width="155" height="155" fill="rgb(38,38,38)" stroke="white" strokeWidth="6" pointerEvents="all" />
              <circle cx="82" cy="82" r="70" fill="rgb(38,38,38)" stroke="white" strokeWidth="6" pointerEvents="all" />
            </g>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="115px" height="115px" viewBox="-0.5 -0.5 165 165">
            <defs />
            <g>
              <rect x="5" y="5" width="155" height="155" fill="rgb(38,38,38)" stroke="white" strokeWidth="6" pointerEvents="all" />
              <circle cx="82" cy="82" r="70" fill="rgb(38,38,38)" stroke="white" strokeWidth="6" pointerEvents="all" />
            </g>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="115px" height="115px" viewBox="-0.5 -0.5 165 165">
            <defs />
            <g>
              <rect x="5" y="5" width="155" height="155" fill="rgb(38,38,38)" stroke="white" strokeWidth="6" pointerEvents="all" />
              <circle cx="82" cy="82" r="70" fill="rgb(38,38,38)" stroke="white" strokeWidth="6" pointerEvents="all" />
            </g>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="115px" height="115px" viewBox="-0.5 -0.5 165 165">
            <defs />
            <g>
              <rect x="5" y="5" width="155" height="155" fill="rgb(38,38,38)" stroke="white" strokeWidth="6" pointerEvents="all" />
              <circle cx="82" cy="82" r="70" fill="rgb(38,38,38)" stroke="white" strokeWidth="6" pointerEvents="all" />
            </g>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="115px" height="115px" viewBox="-0.5 -0.5 165 165">
            <defs />
            <g>
              <rect x="5" y="5" width="155" height="155" fill="rgb(38,38,38)" stroke="white" strokeWidth="6" pointerEvents="all" />
              <circle cx="82" cy="82" r="70" fill="rgb(38,38,38)" stroke="white" strokeWidth="6" pointerEvents="all" />
            </g>
          </svg>
              
        </div>

    </div>
  );
};

export default BattCell;
