import { css, cx } from '@emotion/css';
import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import { TbProps, determineColorNew } from './utils';
import PlotlyChart from './PlotlyChart';
import * as scheme from './EPSScheme'; // Import your styles
import * as htbScheme from './EpsHTBScheme';
import copy from 'copy-to-clipboard';

type VisibleTooltips = { [key: string]: boolean };

const EpsHTb: React.FC<TbProps> = ({ data }) => {
  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});
  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));

    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };

  return (
    <div>
      <Handle type="target" position={Position.Top} id="target-top" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Left} id="target-left" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Left} id="target-left-1" style={{ opacity: 0, top: 100 }} />
      <Handle type="target" position={Position.Right} id="target-right" style={{ opacity: 0 }} />
      {/* <Handle type="target" position={Position.Right} id="target-right-1" style={{ opacity: 0, top:30  }} /> */}

      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Bottom} id="target-bottom-4" style={{opacity:0, left: 850}}/>

      <Handle type="source" position={Position.Top} id="source-top" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Top} id="source-top-1" style={{ opacity: 0, left: 400 }} />
      <Handle type="source" position={Position.Top} id="source-top-2" style={{ opacity: 0, left: 500 }} />
      {/* <Handle type="source" position={Position.Top} id="source-top-1" style={{ opacity: 0, left: 950 }} /> */}
      <Handle type="source" position={Position.Left} id="source-left" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Left} id="source-left-1" style={{ opacity: 0, top:25 }} />
      <Handle type="source" position={Position.Right} id="source-right" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{ opacity: 0 }} />

      <div className={cx(scheme.textboxContainer, htbScheme[data.className as htbScheme.ClassKey])}>
        <p>{data.name}</p>
        <div className={scheme.tbHorContainer}>
          {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.tbValueContainer} key={key}>
              <div className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                {key}:
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
              </div>
              <div className={scheme.tbValueSpanContainer} onClick={() => handleValueChartClick(key)}>
                <span 
                  className={cx(
                    scheme.value, 
                    css`color: ${innerData.dbData?.live}`,
                    scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
                  )}
                >
                  {innerData.dbData?.telemetry}
                </span>
                {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              </div>
              {visibleLimitPops[key] && 
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default EpsHTb;
