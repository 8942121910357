import React, { useEffect} from 'react';
import { PanelProps } from '@grafana/data';
import { PanelDataErrorView } from '@grafana/runtime';
import { useStyles2 } from '@grafana/ui';
import {SimpleOptions } from 'types';
import { TimeRangeContext } from './TimeRangeContext';
import * as dat from 'lil-gui';
import { css, cx } from '@emotion/css';
import App from './EPSApp';
import { usePlayBack } from 'hooks/usePlayback';
import { DrawerProvider } from 'hooks/DrawerContext';
import { Field } from 'utils/type';

interface Props extends PanelProps<SimpleOptions> {}

const getStyles = () => {
  return {
    wrapper: css`
      fontFamily: Open Sans;
      position: relative;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
  };
};

export const SimplePanel: React.FC<Props> = ({ options, data, width, height, fieldConfig, id }) => {

  const styles = useStyles2(getStyles);

  const exampleFields: Field[] = [
    { name: "", type: "", values: [], labels: {} }
  ];

  const selectedData = data.series[0] ? data.series[0].fields : exampleFields;

  //console.log('d', selectedData);
  const limitData = data.series[1] ? data.series[1].fields : exampleFields;
  
  const { processedData, setProcessedData, guiValues } = usePlayBack();

  // lil-gui configuration
  const guiContainerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (guiContainerRef.current) {
      const gui = new dat.GUI({ container: guiContainerRef.current });
      gui.close();
  
      //gui.add(guiValues, 'screenSpacePanning').name('Space Panning');
  
      const f1 = gui.addFolder('Query');
     //f1.add(guiValues, 'startDateTime').name('Start Date Time').listen();
      //f1.add(guiValues, 'endDateTime').name('End Date Time').listen();
  
      const aggController = f1.add(guiValues.current, 'Agg').name('Agg');
      const aggTimeController = f1.add(guiValues.current, 'aggTime').name('Agg Time');
      const aggFnController = f1.add(guiValues.current.aggFn, 'fn', ['last', 'mean', 'sum', 'count']).name('Agg Fn');
      aggFnController.disable();
      aggTimeController.disable();
   
      const lastController = f1.add(guiValues.current, 'Last').name('Last');
      const lastCountController = f1.add(guiValues.current, 'lastCount', 1, 1000, 1).name('Last Count');
      lastCountController.disable();
  
      aggController.onChange((value: boolean) => {
        if (value) {
          aggTimeController.enable();
          aggFnController.enable();
        } else {
          aggTimeController.disable();
          aggFnController.disable();
        }
      });
  
      lastController.onChange((value: boolean) => {
        if (value) {
          lastCountController.enable();
        } else {
          lastCountController.disable();
        }
      });

      const f2 = gui.addFolder('Playback (PT)');
      f2.add( guiValues.current, 'startDateTime').name('Start Date Time');
      f2.add( guiValues.current, 'endDateTime').name('End Date Time');
      f2.add( guiValues.current, 'pbaggTime').name('Agg Time');
      f2.add(guiValues.current.pbaggFn, 'fn', ['last', 'mean', 'sum', 'count']).name('Agg Fn');
      //f1.add( guiValues, 'loadingPercent', 0,100, 1).name('Loading %').disable();
      f2.add( guiValues.current, 'pbplayBack').name('Playback');
      f2.add( guiValues.current, 'pbstopPlay').name('Stop Playback');

      f1.close();
      f2.close();
      if (!guiValues.current.pbisInPlayBack) { 
        setProcessedData({});
      }
      
    }
  }, [guiValues]);

    // Extract the time range as sa
    const timeRange = {
      from: data.timeRange.from, 
      to: data.timeRange.to
    };

  if (data.series.length === 0) {
    return <PanelDataErrorView fieldConfig={fieldConfig} panelId={id} data={data} needsStringField />;
  }

  return (
    <div       
    className={cx(
      styles.wrapper,
      css`
        width: ${width}px;
        height: ${height}px;

        .lil-gui.root{
          --background-color: #d29d00;
          --font-size: 11px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1000;
          --background-color: #002b36;
          --text-color: #b2c2c2;
          --title-background-color: #001f27;
          --title-text-color: #b2c2c2;
          --widget-color: #094e5f;
          --hover-color: #0a6277;
          --focus-color: #0b6c84;
          --number-color: #2aa0f2;
          --string-color: #97ad00;
          --folder-indent: 100px;
          --widget-border-radius: 0px;
        }
      `
    )}>
    <TimeRangeContext.Provider value={{ timeRange, guiValues }}>
    <DrawerProvider>
    <div ref={guiContainerRef} />

        <App dbData={selectedData} width={width} height={height} influxData={processedData} limitData={limitData}></App>
        </DrawerProvider>
        </TimeRangeContext.Provider>

  </div>
   ); 
};
