import React, { useState } from "react";
import { css, cx } from '@emotion/css';
import { InnerData, determineColorNew } from "./utils";
import { Handle, Position } from "reactflow";
import PlotlyChart from "./PlotlyChart";
import * as styles from './EPSScheme'; // Import your styles
import copy from 'copy-to-clipboard';

interface SolarCellProp {
  data: {
    name: string;
    value: InnerData;
    className: string;
  };
}

const solarBox = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

type VisibleTooltips = { [key: string]: boolean };


const SolarCell: React.FC<SolarCellProp> = ({ data }) => {
  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));

    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };

  return (
    <div className={solarBox}>

      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>


        <div>

        {/* <img src={logo} alt="Solar Cell" width={400}/> */}

        </div>

        <div>
          {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={styles.svgValueSpanContainer} key={key}>
            <span className={cx(styles.svgKey, styles.FnameKeyContainer)}onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && <span className={styles.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={styles.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span 
                onClick={() => handleValueChartClick(key)} 
                className={cx(
                  styles.svgValue, 
                  css`color: ${innerData.dbData?.live}`,
                  styles.classMap[determineColorNew(innerData.dbData?.limit)] 
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={styles.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPops[key] && 
                  <div className={styles.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart> 
                    <button className={styles.plotButton} onClick={() => handleValueChartClick(key)}>
                        Close Plot
                    </button>
                  </div>}
            </div>
        ))}</div>
    </div>
  );
};

export default SolarCell;
