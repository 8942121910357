import { css } from '@emotion/css';
import React from 'react';
import { Handle, Position } from 'reactflow';
import { InnerData, TLM } from 'utils/type';

interface BcrSubProps {
  data: {
    value: InnerData;
    name: string;
    power: TLM;
    power1: TLM;
  };
}


const BcrSub: React.FC<BcrSubProps> = ({data}) => {

  const containerStyle = css`
    width: 725px;
    height: 350px;
    background-color: rgb(22, 65, 108);
    border: 5px solid black;
    z-index: 0;
  `;


  return (
    <div className={containerStyle}>
      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-2" style={{opacity:0, top:450}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{opacity:0, top:600}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-2" style={{opacity:0, top:550}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{opacity:0, top:450}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

      <p>{data.name}</p>

    </div>
  )
}

export default BcrSub;
