import React, { useState } from 'react';
import { InnerData, determineColorNew, TLM, handleCopyMneName } from './utils';
import { Handle, Position } from 'reactflow';
import PlotlyChart from './PlotlyChart';
import * as scheme from './EPSScheme'; // Import your styles
import { css, cx } from '@emotion/css';

interface FRWProp {
  data: {
    name: string;
    value: InnerData;
    className: string;
    pwr: TLM;
    spd: TLM;
  };
}

type VisibleTooltips = { [key: string]: boolean };

const RW: React.FC<FRWProp> = ({ data }) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips((prevVisibleTooltips) => ({
      ...prevVisibleTooltips,
      [key]: true,
    }));

    setTimeout(() => {
      setVisibleTooltips((prevVisibleTooltips) => ({
        ...prevVisibleTooltips,
        [key]: false,
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops((prevVisibleLimitPops) => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key],
    }));
  };

  const circleFill = data.pwr && data.pwr.telemetry === 'OFF' ? 'gray' : 'green';
  
  return (
    <div className={`${data.className}`}>
      <Handle type="target" position={Position.Top} id="target-top" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Left} id="target-left" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Right} id="target-right" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Top} id="source-top" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Left} id="source-left" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Right} id="source-right" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{ opacity: 0 }} />

      <div className={css`
      position: relative;
      background-color: rgb(38,38,38);
      `}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          width="161px"
          height="161px"
          viewBox="-0.5 -0.5 161 161"
        >
          <defs />
          <g>
            <rect
              x="3"
              y="3"
              width="155"
              height="155"
              rx="24"
              ry="24"
              fill="rgb(38,38,38)"
              stroke="#FFFFFF"
              strokeWidth="5"
              pointerEvents="all"
            />
            <ellipse
              cx="80"
              cy="80"
              rx="70"
              ry="70"
              fill="rgb(38,38,38)"
              stroke="#FFFFFF"
              strokeWidth="5"
              pointerEvents="all"
            />
            <ellipse
              cx="80"
              cy="80"
              rx="55"
              ry="55"
              fill="rgb(38,38,38)"
              stroke="#FFFFFF"
              strokeWidth="5"
              pointerEvents="all"
            />
            <ellipse
              cx="80"
              cy="80"
              rx="10"
              ry="10"
              fill={circleFill}
              stroke="#FFFFFF"
              strokeWidth="3"
              pointerEvents="all"
            />
          </g>
        </svg>
      </div>

      <p>{data.name}</p>

      <div className={scheme.svgValueColCon}>
        {data.value &&
          Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>
              <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)} onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && (
                  <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>
                    {innerData.dbData.mne}
                  </span>
                )}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>
                  {innerData.fname}
                </span>
              </span>
              <span
                onClick={() => handleValueChartClick(key)}
                className={cx(
                  scheme.svgValue,
                  css`
                    color: ${innerData.dbData?.live};
                  `,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)]
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && (
                <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>
              )}
              {visibleLimitPops[key] && (
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default RW;

