import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import PlotlyChart from './PlotlyChart';
import * as scheme from './EPSScheme';
import * as tbScheme from './TBScheme';
import { css, cx } from '@emotion/css';
import copy from 'copy-to-clipboard';
import { determineColorNew } from 'utils/function';
import { InnerData, TLM } from 'utils/type';

type VisibleTooltips = { [key: string]: boolean };

type solarPorp = {
  data: {
    name: string;
    value: InnerData;
    className: string;
    thisCurr: TLM;
    otherCurr: TLM;
  };
};

const SolarArray: React.FC<solarPorp> = ({ data }) => {
  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});
  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips((prevVisibleTooltips) => ({
      ...prevVisibleTooltips,
      [key]: true,
    }));

    setTimeout(() => {
      setVisibleTooltips((prevVisibleTooltips) => ({
        ...prevVisibleTooltips,
        [key]: false,
      }));
    }, 5000);
  };

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops((prevVisibleLimitPops) => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key],
    }));
  };

  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };

  const getColor = (self: string, other: string) => {
    const selfN = parseFloat(self);
    const otherN = parseFloat(other);
    return selfN > otherN;
  };

  const powerClass = (data.thisCurr && data.otherCurr && getColor(data.thisCurr.telemetry, data.otherCurr.telemetry)) ? scheme.powerClass : '';

  return (
    <div>
      <Handle type="target" position={Position.Top} id="target-top" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Left} id="target-left" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Right} id="target-right" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Top} id="source-top" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Left} id="source-left" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Right} id="source-right" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{ opacity: 0 }} />

      <div className={cx(scheme.textboxContainer, tbScheme[data.className as tbScheme.ClassKey], powerClass)}>
        <p>{data.name}</p>

        <div
          className={css`
            display: flex;
            flex-wrap: wrap;
            row-gap: 10px;
            justify-content: space-evenly;
          `}
        >
          {/* First element - Full width  */}
          {data.value && Object.entries(data.value).map(([key, innerData], index) => (
            <div
              key={key}
              className={css`
                ${index === 0 ? 'flex: 1 0 100%;' : ''}
              `}
            >
              {index === 0 ? (
                <div className={scheme.tbValueContainer} key={key} >
                <div key={key} className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                  {key}:
                  {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                  <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
                </div>
                <div className={scheme.tbValueSpanContainer} onClick={() => handleValueChartClick(key)}>
                  <span 
                    className={cx(
                      scheme.value, 
                      css`color: ${innerData.dbData?.live}`,
                      scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
                    )}
                  >
                    {innerData.dbData?.telemetry}
                  </span>
                  {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
                </div>
                {visibleLimitPops[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                      Close Plot
                    </button>
                  </div>}
              </div>
              ) : (
                // Square box SVG for subsequent elements
                <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="-0.5 -0.5 100 100">
                  <rect
                    x="5"
                    y="5"
                    width="90"
                    height="90"
                    fill={innerData.dbData?.telemetry === 'ON' ? 'green' : 'gray'}
                    stroke="white"
                    strokeWidth="6"
                  />
                  <text x="50" y="55" textAnchor="middle" fill="white" fontSize="25">
                    {key.split(' ')[0]}
                  </text>
                </svg>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SolarArray;
