
import React from 'react';
import { Handle, Position } from 'reactflow';
import { css } from '@emotion/css';
import { InnerData, TLM } from 'utils/type';

interface BcrMainProps {
  data: {
    value: InnerData;
    name: string;
    power: TLM;
    power1: TLM;
  };
}

const BcrMain: React.FC<BcrMainProps> = ({data}) => {

  const mainContainerStyle = css`
  width: 2425px;
  height: 475px;
  background-color: rgb(22, 65, 108);
  border: 5px solid black;
  `;

  return (
    <div className={mainContainerStyle}>
      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Top} id="target-top-1" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-1" style={{opacity:0, top:300}}/>
      <Handle type="target" position={Position.Left} id="target-left-2" style={{opacity:0, top:439.5}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{opacity:0, top:300}}/>
      <Handle type="target" position={Position.Right} id="target-right-2" style={{opacity:0, top:439.5}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top-1" style={{opacity:0, left:850}}/>
      <Handle type="source" position={Position.Top} id="source-top-2" style={{opacity:0, left:1500}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-2" style={{opacity:0, top:550}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{opacity:0, top:450}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

      <p> (IAU)</p>

    </div>
  )
}

export default BcrMain;
