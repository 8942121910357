// createNodes.ts

import { TelemetryDictionary } from 'utils/type';
import { source } from './function';
import { Node } from 'reactflow';

export const createNodes = (
  telemetryDictionary: TelemetryDictionary,
  pbTime: string,
  pbLoop: number,
) => {
  const nodes: Node[] = [
    { id: 'Panel', type: 'epsPanel', position: { x: 550, y: 50 }, data: { 'source': source } },
    { id: 'placeHolder1', type: 'placeHolder', parentNode: 'Panel', position: { x: 1425, y: 1275 }, data: { text: 'To All Loads' } },
    { id: 'placeHolder2', type: 'placeHolder', parentNode: 'Panel', position: { x: 25, y: 1315 }, data: { text: 'Power Gen' } },
    { id: 'placeHolder3', type: 'placeHolder', parentNode: 'Panel', position: { x: 25, y: 1275 }, data: { text: 'Power Loads' } },

    { id: 'Liveplot', type: 'liveplot', parentNode: 'Panel', position: { x: 1300, y: 1975 }, data: { name: 'Battery Voltage', mne: 'pwbv', sc: source } },
    { id: 'Liveplot2', type: 'liveplot', parentNode: 'Panel', position: { x: 2300, y: 1975 }, data: { name: 'Battery Current', mne: 'pwbi', sc: source } },

    { id: 'HDRM2', type: 'actuator', parentNode: '', position: { x: 3700, y: 1000 }, data: { name: 'HDRM2', value: { 'En': { 'dbData': telemetryDictionary['En2'], 'fname': '' }, 'V': { 'dbData': telemetryDictionary['Hdrm2 Volt'], 'fname': '' } } } },
    { id: 'HDRM1', type: 'actuator', parentNode: '', position: { x: 400, y: 1000 }, data: { name: 'HDRM1', value: { 'En': { 'dbData': telemetryDictionary['En1'], 'fname': '' }, 'V': { 'dbData': telemetryDictionary['Hdrm1 Volt'], 'fname': '' } } } },

    { id: 'Battery', type: 'epstextbox', parentNode: 'Panel', position: { x: 700, y: 2400 }, data: { name: 'Battery', value: { "Curr": { 'dbData': telemetryDictionary['Battery Curr'], 'fname': '' }, "Volt": { 'dbData': telemetryDictionary['Battery Volt'], 'fname': '' }, "Temp": { 'dbData': telemetryDictionary['Battery Temp'], 'fname': '' }, } } },
    { id: 'BRB', type: 'epstextbox', parentNode: 'Panel', position: { x: 700, y: 2000 }, data: { name: 'Battery Relay Box (BRB)', className: 'BrbBox', oriHeight: '150px', exWidth: '475px', value: { 'K1k2 Coil State': { 'dbData': telemetryDictionary['Brb K1k2 Coil State'], 'fname': '' }, 'Rly1 Sep State': { 'dbData': telemetryDictionary['Brb Sep Rly1 State'], 'fname': '' }, 'Rly2 Sep State': { 'dbData': telemetryDictionary['Brb Sep Rly2 State'], 'fname': '' }, "Temp": { 'dbData': telemetryDictionary['Brb Temp'], 'fname': '' } } } },
    { id: 'SolarArray', type: 'solar', parentNode: 'Panel', position: { x: 50, y: 1950 }, data: { name: 'Solar Array', value: { "SA Curr": { 'dbData': telemetryDictionary['SA Current'], 'fname': '' }, "SA1 Pwr": { 'dbData': telemetryDictionary['SA1 Pwr'], 'fname': '' }, "SA2 Pwr": { 'dbData': telemetryDictionary['SA2 Pwr'], 'fname': '' }, "SA3 Pwr": { 'dbData': telemetryDictionary['SA3 Pwr'], 'fname': '' }, "SA4 Pwr": { 'dbData': telemetryDictionary['SA4 Pwr'], 'fname': '' }, "SA5 Pwr": { 'dbData': telemetryDictionary['SA5 Pwr'], 'fname': '' }, "SA6 Pwr": { 'dbData': telemetryDictionary['SA6 Pwr'], 'fname': '' }, "SA7 Pwr": { 'dbData': telemetryDictionary['SA7 Pwr'], 'fname': '' }, "SA8 Pwr": { 'dbData': telemetryDictionary['SA8 Pwr'], 'fname': '' }, "SA9 Pwr": { 'dbData': telemetryDictionary['SA9 Pwr'], 'fname': '' }, "SA10 Pwr": { 'dbData': telemetryDictionary['SA10 Pwr'], 'fname': '' }, "SA11 Pwr": { 'dbData': telemetryDictionary['SA11 Pwr'], 'fname': '' }, "SA12 Pwr": { 'dbData': telemetryDictionary['SA12 Pwr'], 'fname': '' } } } },

    { id: 'IAU', type: 'bcrMain', parentNode: 'Panel', position: { x: 400, y: 1400 }, data: {} },
    { id: 'SACI', type: 'epsHTb', parentNode: 'IAU', position: { x: 750, y: 300 }, data: { name: 'SACI', className: 'BcrHub', value: { "Current": { 'dbData': telemetryDictionary['Battery Curr SACI'], 'fname': '' }, "Voltage": { 'dbData': telemetryDictionary['Battery Volt SACI'], 'fname': '' } } } },
    { id: 'PAPI6-3', type: 'epstextbox', parentNode: 'IAU', position: { x: 1850, y: 50 }, data: { name: 'PAPI6-3', value: { 'PAPI3 Current': { 'dbData': telemetryDictionary['PAPI3 Current'], 'fname': '' } } } },
    { id: 'PAPI6-2', type: 'epstextbox', parentNode: 'IAU', position: { x: 1250, y: 50 }, data: { name: 'PAPI6-2', value: { 'PAPI2 Current': { 'dbData': telemetryDictionary['PAPI2 Current'], 'fname': '' } } } },
    { id: 'PAPI6-1', type: 'epstextbox', parentNode: 'IAU', position: { x: 650, y: 50 }, data: { name: 'PAPI6-1', value: { 'PAPI1 Current': { 'dbData': telemetryDictionary['PAPI1 Current'], 'fname': '' } } } },
    { id: 'MCLASI', type: 'epstextbox', parentNode: 'IAU', position: { x: 50, y: 50 }, data: { name: 'MCLASI', value: { '5v': { 'dbData': telemetryDictionary['MCLASI 5V'], 'fname': '' }, '2.5V': { 'dbData': telemetryDictionary['MCLASI 2.5V'], 'fname': '' }, '3.3V': { 'dbData': telemetryDictionary['MCLASI Bus 3.3V'], 'fname': '' } } } },

    { id: 'ST', type: 'epstextbox', parentNode: 'Panel', position: { x: 2750, y: 400 }, data: { name: 'Star Tracker', className: 'SBox', value: { 'Pwr': { 'dbData': telemetryDictionary['St Pwr'], 'fname': '' }, "Aux Volt": { 'dbData': telemetryDictionary['St Aux Volt'], 'fname': '' }, "BRAM Volt": { 'dbData': telemetryDictionary['St BRAM Volt'], 'fname': '' }, "INT Volt": { 'dbData': telemetryDictionary['St INT Volt'], 'fname': '' }, "ODDR Volt": { 'dbData': telemetryDictionary['St ODDR Volt'], 'fname': '' }, "PAUX Volt": { 'dbData': telemetryDictionary['St PAUX Volt'], 'fname': '' }, "PINT Volt": { 'dbData': telemetryDictionary['St PINT Volt'], 'fname': '' }, "REF FN Volt": { 'dbData': telemetryDictionary['St REF FN Volt'], 'fname': '' }, "REF FP Volt": { 'dbData': telemetryDictionary['St REF FP Volt'], 'fname': '' }, "OVP/VNDDR Volt": { 'dbData': telemetryDictionary['St VP/VN Volt'], 'fname': '' } } } },
    { id: 'RW1', type: 'rw', parentNode: 'Panel', position: { x: 1950, y: 250 }, data: { name: 'RW 1', pwr:telemetryDictionary['RW1 Pwr'],value: { 'Mi': { 'dbData': telemetryDictionary['RW1 MOTORi'], 'fname': 'Motor Current' },'Mv': { 'dbData': telemetryDictionary['RW1 MOTORv'], 'fname': 'Motor Voltage' },'IFB': { 'dbData': telemetryDictionary['RW1 BUSi'], 'fname': 'Current From Bus' },'VFB': { 'dbData': telemetryDictionary['RW1 BUSv'], 'fname': 'Voltage From Bus' }}}},
    { id: 'RW2', type: 'rw', parentNode: 'Panel', position: { x: 2350, y: 250 }, data: { name: 'RW 2', pwr:telemetryDictionary['RW2 Pwr'],value: { 'Mi': { 'dbData': telemetryDictionary['RW2 MOTORi'], 'fname': 'Motor Current' },'Mv': { 'dbData': telemetryDictionary['RW2 MOTORv'], 'fname': 'Motor Voltage' },'IFB': { 'dbData': telemetryDictionary['RW2 BUSi'], 'fname': 'Current From Bus' },'VFB': { 'dbData': telemetryDictionary['RW2 BUSv'], 'fname': 'Voltage From Bus' }}}},
    { id: 'RW3', type: 'rw', parentNode: 'Panel', position: { x: 1950, y: 750 }, data: { name: 'RW 3', pwr:telemetryDictionary['RW3 Pwr'],value: { 'Mi': { 'dbData': telemetryDictionary['RW3 MOTORi'], 'fname': 'Motor Current' },'Mv': { 'dbData': telemetryDictionary['RW3 MOTORv'], 'fname': 'Motor Voltage' },'IFB': { 'dbData': telemetryDictionary['RW3 BUSi'], 'fname': 'Current From Bus' },'VFB': { 'dbData': telemetryDictionary['RW3 BUSv'], 'fname': 'Voltage From Bus' }}}},
    { id: 'RW4', type: 'rw', parentNode: 'Panel', position: { x: 2350, y: 750 }, data: { name: 'RW 4', pwr:telemetryDictionary['RW4 Pwr'],value: { 'Mi': { 'dbData': telemetryDictionary['RW4 MOTORi'], 'fname': 'Motor Current' },'Mv': { 'dbData': telemetryDictionary['RW4 MOTORv'], 'fname': 'Motor Voltage' },'IFB': { 'dbData': telemetryDictionary['RW4 BUSi'], 'fname': 'Current From Bus' },'VFB': { 'dbData': telemetryDictionary['RW4 BUSv'], 'fname': 'Voltage From Bus' }}}},
    { id: 'Heater', type: 'epstextbox', parentNode: 'Panel', position: { x: 1250, y: 300 }, data: { name: 'Heater', className: 'HeaterBox', value: { "Battery Pwr": { 'dbData': telemetryDictionary['Battery Heater Pwr'], 'fname': '' }, "Payload Pwr": { 'dbData': telemetryDictionary['Payload Op Heater Pwr'], 'fname': '' }, "NFI Cam Pwr": { 'dbData': telemetryDictionary['NFI Cam Heater Pwr'], 'fname': '' }, "WFI Cam Pwr": { 'dbData': telemetryDictionary['WFI Cam Heater Pwr'], 'fname': '' }, "N2H4 Pwr": { 'dbData': telemetryDictionary['N2H4 Heater Pwr'], 'fname': '' }, "PF Pwr": { 'dbData': telemetryDictionary['PF Heater Pwr'], 'fname': '' }, "PLV Pwr": { 'dbData': telemetryDictionary['PLV Heater Pwr'], 'fname': '' }, "PT Pwr": { 'dbData': telemetryDictionary['PT Heater Pwr'], 'fname': '' }, "ST Pwr": { 'dbData': telemetryDictionary['ST Heater Pwr'], 'fname': '' }, "GN2 Pwr": { 'dbData': telemetryDictionary['GN2 Heater Pwr'], 'fname': '' } } } },
    { id: 'S-Band', type: 'epstextbox', parentNode: 'Panel', position: { x: 150, y: 550 }, data: { name: 'S-Band', className: 'SBBox', value: { 'Tx Pwr': { 'dbData': telemetryDictionary['Tx Pwr'], 'fname': '' }, 'Tx 18v': { 'dbData': telemetryDictionary['Tx 18v'], 'fname': '' }, 'NB 10V': { 'dbData': telemetryDictionary['NB 10V'], 'fname': '' }, 'PA 5v': { 'dbData': telemetryDictionary['PA 5v'], 'fname': '' } } } },

    { id: 'Payload', type: 'epsHTb', parentNode: 'Panel', position: { x: 200, y: 50 }, data: { name: 'Payload', className: 'PayloadBox', value: { "NFI Cam Pwr": { 'dbData': telemetryDictionary['NFI Cam Pwr'], 'fname': '' }, "NFI Cam Curr": { 'dbData': telemetryDictionary['NFI Cam Current'], 'fname': '' }, "WFI Cam Pwr": { 'dbData': telemetryDictionary['NFI Cam Pwr'], 'fname': '' }, "WFI Cam Curr": { 'dbData': telemetryDictionary['WFI Cam Current'], 'fname': '' }, "GCI Pwr": { 'dbData': telemetryDictionary['GCI Pwr'], 'fname': '' }, "GCI Curr": { 'dbData': telemetryDictionary['GCI Current'], 'fname': '' }, "GISSMO Pwr": { 'dbData': telemetryDictionary['GISSMO Pwr'], 'fname': '' }, "GISSMO Curr": { 'dbData': telemetryDictionary['GISSMO Current'], 'fname': '' }, "FW Pwr": { 'dbData': telemetryDictionary['FW Pwr'], 'fname': '' }, "FW Curr": { 'dbData': telemetryDictionary['FW Current'], 'fname': '' }, "Dled Pwr": { 'dbData': telemetryDictionary['Dled Pwr'], 'fname': '' }, "DOOR Curr": { 'dbData': telemetryDictionary['DOOR Current'], 'fname': '' } } } },

    { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:550, y:-105}, data:{name:'', playBackValue: {time: pbTime, loop: pbLoop}}},

  ];

  return nodes;
};
