import { decode } from "@msgpack/msgpack";
import { getTemplateSrv } from "@grafana/runtime";
import copy from 'copy-to-clipboard';
import { InnerData, LimitProp } from "./type";

export const nameToMne = {

  /* Payload */
  "DOOR Current": "iop_door_pwr_imon", /* NFI and WFI aperture door current monitor */
  "FW Current": "iop_fw_pwr_imon", /* Filter Wheel current monitor */
  "GCI Current": "iop_gci_pwr_imon", /* LVPS GCI current monitor */
  "GISSMO Current": "iop_gissmo_pwr_imon", /* GISSMO current monitor */
  "ICP Current": "iop_icp_pwr_imon", /* LVPS ICP current monitor */

  "Dled Pwr": "iop_dled_pwr", /* DLED power */
  "FW Pwr": "iop_fw_pwr", /* Filter wheel power */
  "GCI Pwr": "iop_gci_pwr", /* GCI power enable (readback of INST_PWR_CTL.GCI_PWR_ENABLE). */
  "GISSMO Pwr": "iop_gissmo_pwr", /* GISSMO power */
  //"": "iop_idx_sensor_pwr", /* IDX (Filter wheel Hall sensor) power */
  "NFI Cam Pwr": "iop_nfi_cam_pwr", /* NFI Camera power */
  "WFI Cam Pwr": "iop_wfi_cam_pwr", /* WFI Camera power */
  // "": "nfiwfiactpw", /* NFI & WFI Cover Actuator Power */
  // "": "nfiwfifwrpw", /* NFI & WFI Filter Wheel Actuator Power */

  /* Reaction Wheel */
  "RW1 BUSv": "rw1busv", /* Wheel 1 Raw Telemetry - Bus Voltage */
  "RW1 MOTORv": "rw1motorv", /* Wheel 1 Raw Telemetry - Motor Voltage */
  "RW2 BUSv": "rw2busv", /* Wheel 2 Raw Telemetry - Bus Voltage */
  "RW2 MOTORv": "rw2motorv", /* Wheel 2 Raw Telemetry - Motor Voltage */
  "RW3 BUSv": "rw3busv", /* Wheel 3 Raw Telemetry - Bus Voltage */
  "RW3 MOTORv": "rw3motorv", /* Wheel 3 Raw Telemetry - Motor Voltage */
  "RW4 BUSv": "rw4busv", /* Wheel 4 Raw Telemetry - Bus Voltage */
  "RW4 MOTORv": "rw4motorv", /* Wheel 4 Raw Telemetry - Motor Voltage */
  "RW1 BUSi": "rw1busi", /* Wheel 1 Raw Telemetry - Bus Current */
  "RW1 MOTORi": "rw1motori", /* Wheel 1 Raw Telemetry - Motor Current */
  "RW2 BUSi": "rw2busi", /* Wheel 2 Raw Telemetry - Bus Current */
  "RW2 MOTORi": "rw2motori", /* Wheel 2 Raw Telemetry - Motor Current */
  "RW3 BUSi": "rw3busi", /* Wheel 3 Raw Telemetry - Bus Current */
  "RW3 MOTORi": "rw3motori", /* Wheel 3 Raw Telemetry - Motor Current */
  "RW4 BUSi": "rw4busi", /* Wheel 4 Raw Telemetry - Bus Current */
  "RW4 MOTORi": "rw4motori", /* Wheel 4 Raw Telemetry - Motor Current */
  "RW1 Pwr": "rw1pw", /* RW 1 Switched Power */
  "RW2 Pwr": "rw2pw", /* RW 2 Switched Power */
  "RW3 Pwr": "rw3pw", /* RW 3 Switched Power */
  "RW4 Pwr": "rw4pw", /* RW 4 Switched Power */
  
    "RW1 Speed/dir": "adrw1sdir", /* Reaction Wheel 1 Speed/Dir*/
    "RW2 Speed/dir": "adrw2sdir", /* Reaction Wheel 1 Speed/Dir*/
    "RW3 Speed/dir": "adrw3sdir", /* Reaction Wheel 1 Speed/Dir*/
    "RW4 Speed/dir": "adrw4sdir", /* Reaction Wheel 1 Speed/Dir*/

  /* S-Band */
  // "": "rxifss", /* Measurement of signal power in the IF bandwidth. Reported Value is the gain table index from AD9364 */
  // "": "rxss", /* CohAgc Measurement of signal power in the carrier tracking loop */
  // "": "nbxfwdpw", /* Narrowband Transponder Fwd Power Out */
  "Tx Pwr": "nbxtxpw", /* Measurement of the transmitter power */
  "NB 10V": "nbxp10v", /* NB Secondary Voltage 10V */
  "PA 5v": "nbxpasv", /* S-band PA Secondary Voltage 5V */
  "Tx 18v": "nbxtxp18v", /* Measurement of the transmitter +18V/+28V secondary */
  "Pri Tx Pwr": "nbtxppw", /* Primary Transmitter Power */
  "Red Tx Pwr": "nbtxrpw", /* Redundant Transmitter Power */

  /* IAU */
  "MCLASI 5V": "pwmclasi5v", /* Internal MCLASI 5V status */
  "MCLASI Bus 28V": "pwmclasibusv", /* 28V Voltage */
  "MCLASI 2.5V": "pwmclasip2p5v", /* Internal MCLASI 2.5V status */
  "MCLASI 3.3V": "pwmclasip3p3v", /* Internal MCLASI 3.3V status */
  "PAPI1 Current": "pwpapi1i", /* PAPI1 Current */
  "PAPI2 Current": "pwpapi2i", /* PAPI2 Current */
  "PAPI3 Current": "pwpapi3i", /* PAPI3 Current */

  /* Star Tracker */
  "St Aux Volt": "stvccaux", /* Star Tracker AUX Voltage */
  "St BRAM Volt": "stvccbram", /* Star Tracker BRAM Voltage */
  "St INT Volt": "stvccint", /* Star Tracker INT Voltage */
  "St ODDR Volt": "stvccoddr", /* Star Tracker ODDR Voltage */
  "St PAUX Volt": "stvccpaux", /* Star Tracker PAUX Voltage */
  "St PINT Volt": "stvccpint", /* Star Tracker PINT Voltage */
  "St REF FN Volt": "stvccreffn", /* Star Tracker REF FN Voltage */
  "St REF FP Volt": "stvccreffp", /* Star Tracker REF FP Voltage */
  "St VP/VN Volt": "stvccvpvn", /* Star Tracker VP/VN Voltage */
  "St Pwr": "stpw", /* Star Tracker Power Switch Status */

  /* Heater */
  "Battery Heater Pwr": "htrbattintpw", /* Battery Heater Power */
  "Bench Heater Pwr": "htrbenchfwdpw", /* Bench Survival Heater */
  "Thermal Cat Bed Heater Pwr Pri": "htrcatppw", /* Thermal Catalyst Bed Heater Primary */
  "Thermal Cat Bed Heater Pwr Red": "htrcatrpw", /* Thermal Catalyst Bed Heater Redundant */
  "FDV Heater Pwr": "htrfilldrainvalven2h4pw", /* FDV Heater */
  "Gissmo Heater Pwr": "htrgssmpw", /* Gissmo Electronics Survival Heater */
  "Internal Lines Heater Pwr": "htrintlinespw", /* Internal Lines Heater */
  "NFI Cam Heater Pwr": "htrnficampw", /* NFI Cam Survival Heater */
  "WFI Cam Heater Pwr": "htrwficampw", /* WFI Cam Survival Heater */
  "Payload Op Heater Pwr": "htrplpw", /* P/L Operational Heater Power */
  "PF Heater Pwr": "htrproppfpw", /* PF Heater Power */
  "PLV Heater Pwr": "htrpropplvpw", /* PLV Heater Power */
  "PT Heater Pwr": "htrpropptpw", /* PT Heater Power */
  "PZ Heater Pwr": "htrpzmypw", /* PZ Panel Heater Power */
  "ST Heater Pwr": "htrstohpw", /* Star Tracker OH Heater */
  "Bracket Heater Pwr": "htrtankbrackpw", /* Bracket Tank Heater */
  "GN2 Heater Pwr": "htrtankgn2pw", /* GN2 Tank Heater */
  "N2H4 Heater Pwr": "htrtankn2h4pw", /* N2H4 Tank Heater */
  "DVT 1 Heater Pwr": "htrthrustervalve1vpw", /* DVT 1 Valve Heater */
  "DVT 2 Heater Pwr": "htrthrustervalve2vpw", /* DVT 2 Valve Heater */
  "DVT 3 Heater Pwr": "htrthrustervalve3vpw", /* DVT 3 Valve Heater */
  "DVT 4 Heater Pwr": "htrthrustervalve4vpw", /* DVT 4 Valve Heater */

  /* IRU */
  //"": "irupw", /* IRU Switched Power */

  /* Solar Array */
  "SA Current":"pwsacisai", /* SA Current */
  "SA1 Pwr":"pwsa1pw", /* SA Switch Status: 1 */
  "SA2 Pwr":"pwsa2pw", /* SA Switch Status: 2 */
  "SA3 Pwr":"pwsa3pw", /* SA Switch Status: 3 */
  "SA4 Pwr":"pwsa4pw", /* SA Switch Status: 4 */
  "SA5 Pwr":"pwsa5pw", /* SA Switch Status: 5 */
  "SA6 Pwr":"pwsa6pw", /* SA Switch Status: 6 */
  "SA7 Pwr":"pwsa7pw", /* SA Switch Status: 7 */
  "SA8 Pwr":"pwsa8pw", /* SA Switch Status: 8 */
  "SA9 Pwr":"pwsa9pw", /* SA Switch Status: 9 */
  "SA10 Pwr":"pwsa10pw", /* SA Switch Status: 10 */
  "SA11 Pwr":"pwsa11pw", /* SA Switch Status: 11 */
  "SA12 Pwr":"pwsa12pw", /* SA Switch Status: 12 */

  /* Prop */
  "": "prpressv", /* Pressure Transducer Voltage */
  "": "prlvclpw", /* Latch Valve Close Power */
  "": "prlvoppw", /* Latch Valve Open Power */

  /* Battery */
  "Battery Curr":"pwbi", /* Battery current */
  "Battery Temp":"pwbt", /* Battery temperature */
  "Battery Volt":"pwbv", /* Battery voltage */

  /* BRB */
  "Brb K1k2 Coil State":"brb1coilstate", /* K1K2 Coil State */
  "Brb Sep Rly1 State":"brbrly1state", /* SEP_RLY1_STATE */
  "Brb Sep Rly2 State":"brbrly2state", /* SEP_RLY2_STATE */
  "Brb Temp":"t27brbt", /* Temp, Battery Relay */

  /* SACI */
  "Battery Curr SACI":"pwsacibi", /* Battery Current from SACI */
  "Battery Volt SACI":"pwsacibv", /* Battery Voltage from SACI */
  

}

export function determineColor(value: number, limit: LimitProp){
  if (limit.rl !== null && value < limit.rl){
    return 'red-limit';
  }else if (limit.rh !== null && value > limit.rh){
    return 'red-limit';
  }else if (limit.rl !== null && limit.yl !== null && value >= limit.rl && value < limit.yl) {
    return 'yellow-limit';
  }else if (limit.yh !== null && limit.rh !== null && value >= limit.yh && value < limit.rh) {
    return 'yellow-limit';
  }else if (limit.yl !== null && limit.yh !== null && value >= limit.yl && value < limit.yh) {
    return 'green-limit';
  }else if (limit.rl !== null && limit.rh !== null && value >= limit.rl && value < limit.rh){
    return 'green-limit';
  }else{
    return 'gray-limit';
  }
}

export function determineColorNew(name: string){
  if (name === 'rl' || name === 'rh' || name === 'RH' || name === 'RL'){
    return 'red-limit';
  }
  else if (name === 'yl' || name === 'yh' || name === 'YH' || name === 'YL'){
      return 'yellow-limit';
  }else{
    return 'gray-limit';
  }
}

export function lookUpName(mne: string) {
  for (const [key, value] of Object.entries(nameToMne)) {
    if (value.toLowerCase() === mne.toLowerCase()) {
      return key;
    }
  }
  return "NoName"; // Return null if the value is not found
}

export function generateMneString() {
  return Object.values(nameToMne)
    .map((value) => `mnes/\${ScName}/${value}/base64`)
    .join(';');
}

export const decodeFunc = (data: string) => {

  if (!data || data === "") {
    return null;
  }

  const binaryString = window.atob(data);
        
  // Convert binary string to Uint8Array
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  
  // Decode the MessagePack data
  const decodedObject = decode(bytes);
  
  return decodedObject;
}

export type redisDataType = {
  apid: number;
  cnv: string;
  facility: string;
  is_pseudo: boolean;
  mne: string;
  raw: number;
  scid: number;
  t_insert: number;
  t_pkt: number;
  vcid: number;
};

const variableName = 'ScName'; // Replace with your Grafana variable
export const source = getTemplateSrv().replace(`\${${variableName}}`);

export const handleCopyMneName = (mne: string) => {
  copy(mne);
};

export function determineLimit(stringValue: string, limit: LimitProp){

  const value = parseFloat(stringValue);

  if (limit.rl !== null && value < limit.rl){
    return 'rl';
  }else if (limit.rh !== null && value > limit.rh){
    return 'rh';
  }else if (limit.rl !== null && limit.yl !== null && value >= limit.rl && value < limit.yl) {
    return 'rl';
  }else if (limit.yh !== null && limit.rh !== null && value >= limit.yh && value < limit.rh) {
    return 'rh';
  }
  else {
    return "";
  }
}

export const checkOutOfLimitsEx = (data: InnerData) => {
  let sawRed = false, sawYellow = false, sawLive = false, sawWithin12 = false;
  Object.entries(data).forEach(([key, innerData]) => {
    if (innerData && innerData.dbData && innerData.dbData?.limit) {
      const limitClass = determineColorNew(innerData.dbData?.limit);
      if (limitClass === 'red-limit') {
        sawRed = true;
      }
      else if (limitClass === 'yellow-limit') {
        sawYellow = true; 
      }
    }
    else if (innerData.dbData?.live === 'rgb(72, 200, 44)') {
      sawLive = true;
    }
    else if (innerData.dbData?.live === '#CA51EC') {
      sawWithin12 = true;
    }
  });
  if (sawRed) {
    return `rgb(246, 80, 80)`;
  }
  else if (sawYellow) {
    return `rgb(230, 230, 65)`;
  }
  else if (sawLive) {
    return `rgb(72, 200, 44)`;
  }
  else if (sawWithin12) {
    return `#CA51EC`;
  }else {
    return `black`;
  }
};

export const checkOutOfLimitsHid = (data: InnerData) => {
  //console.log(data);
  let sawRed = false, sawYellow = false, sawLive = false, sawWithin12 = false;
  Object.entries(data).forEach(([key, innerData]) => {
    if (innerData && innerData.dbData && innerData.dbData?.limit) {
      const limitClass = determineColorNew(innerData.dbData?.limit);
      if (limitClass === 'red-limit') {
        sawRed = true;
      }
      else if (limitClass === 'yellow-limit') {
        sawYellow = true; 
      }
    }
    else if (innerData.dbData?.live === 'rgb(72, 200, 44)') {
      sawLive = true;
    }
    else if (innerData.dbData?.live === '#CA51EC') {
      sawWithin12 = true;
    }
  });
  if (sawRed) {
    return `#d10808`;
  }
  else if (sawYellow) {
    return `rgb(230, 230, 65)`;
  }
  else if (sawLive) {
    return `rgb(72, 200, 44)`;
  }
  else if (sawWithin12) {
    return `#A020F0`;
  }
  else {
    return `gray`;
  }
};

