import { css } from '@emotion/css';
import React from "react";
import { Handle, Position } from "reactflow";
import { InnerData } from 'utils/type';

interface ActuatorProp {
  data: {
    name: string;
    value: InnerData;
    className: string;
  };
}

const Actuator: React.FC<ActuatorProp> = ({ data }) => {

  const actbox = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  `;

  return (
    <div className={actbox}>

      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

      <p>&nbsp;</p>

     
        {/* <div >
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="290px" height="140px" viewBox="-0.5 -0.5 482 242">
          <defs/>
          <g>
            <g>
              <rect x="120" y="120" width="240" fill="rgb(38,38,38)" stroke="white" strokeWidth="6" height="120"  pointerEvents="all"/>
            </g>
            <g>
              <path d="M 118.82 180 L 0 180" fill="rgb(38,38,38)" stroke="white" strokeWidth="6"  stroke-miterlimit="10" pointerEvents="stroke"/>
            </g>
            <g>
              <path d="M 480 180 L 360 180" fill="rgb(38,38,38)" stroke="white" strokeWidth="6"  stroke-miterlimit="10" pointerEvents="stroke"/>
            </g>
            <g>
              <path d="M 240 120 L 240 0" fill="rgb(38,38,38)" stroke="white" strokeWidth="6"  stroke-miterlimit="10" stroke-dasharray="3 3" pointerEvents="stroke"/>
            </g>
          </g>
        </svg>

        </div> */}

        {/* <p>{data.name}</p>

        <div className={styles.svgValueColCon}>
          {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={styles.svgValueSpanContainer} key={key}>
            <span className={styles.svgKey} onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && <span className={styles.mnameTooltip}>{innerData.dbData.mne}</span>}
                <span className={styles.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span 
                onClick={() => handleValueChartClick(key)} 
                className={css`
                  ${styles.svgValue}; 
                  ${styles.classMap[determineColorNew(innerData.dbData.limit)]}; 
                  ${innerData.dbData.live === 'live' ? styles.live : ''}
                `}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData.unit && <span className={styles.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPops[key] && 
                  <div className={styles.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart> 
                    <button className={styles.plotButton} onClick={() => handleValueChartClick(key)}>
                        Close Plot
                    </button>
                  </div>}
            </div>
        ))}</div> */}
    </div>
  );
};

export default Actuator;
